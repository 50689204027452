<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between flex-wrap">
        <h1>{{ $t('appeals.title') }}</h1>
        <b-button
          variant="primary"
          class="d-flex align-items-center"
          @click="$router.push({ name: 'appeal-create' })"
        >
          <i class="las la-plus-circle mr-25 la-lg" />
          <!-- Nouvelle fiche contact -->
          {{ $t('appeals.create_button') }}
        </b-button>
      </div>
      <hr />
      <div>
        <app-collapse>
          <app-collapse-item
            :title="$t('complaints.complaint_list.filters.title')"
          >
            <b-card-body>
              <b-form @submit.prevent>
                <b-row>
                  <b-col md="12">
                    <b-row>
                      <!-- Message de le appeal -->
                      <b-col md="6">
                        <b-form-group
                          :label="$t('appeals.search_message')"
                          label-for="message"
                          label-cols-md="12"
                        >
                          <b-form-input
                            id="message"
                            v-model="filters.message"
                            type="text"
                            :placeholder="
                              $t('appeals.search_message_placeholder')
                            "
                          />
                        </b-form-group>
                      </b-col>
                      <!-- Profil -->
                      <b-col md="6">
                        <b-form-group
                          :label="$t('appeals.search_requests_associed')"
                          label-for="profils"
                          label-cols-md="12"
                        >
                          <v-select
                            id="new_request"
                            v-model="filters.demandes"
                            :placeholder="''"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="label"
                            :reduce="(option) => option"
                            :options="requests_table_data"
                            debounce="500"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- Status de la appeal -->
                      <b-col md="6">
                        <b-form-group
                          :label="$t('appeals.search_status')"
                          label-for="profils"
                          label-cols-md="12"
                        >
                          <v-select
                            id="profils"
                            v-model="filters.status"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="listStatus"
                            :reduce="(option) => option.alias"
                            :placeholder="
                              $t(
                                'complaints.complaint_list.filters.status_placeholder'
                              )
                            "
                          />
                        </b-form-group>
                      </b-col>
                      <!-- Période -->
                      <b-col md="6">
                        <b-form-group
                          :label="$t('appeals.search_period')"
                          label-for="periode"
                          label-cols-md="12"
                        >
                          <flat-pickr
                            id="periode"
                            v-model="filters.periode"
                            class="form-control"
                            :config="{ mode: 'range' }"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <div class="text-right mt-2">
                  <b-button
                    type="submit"
                    variant="warning"
                    @click="searchAppeal"
                  >
                    <b-spinner v-if="isLoading" small />
                    <feather-icon v-else icon="SearchIcon" class="mr-25" />
                    &nbsp; {{ $t('button.search') }}
                  </b-button>
                </div>
              </b-form>
            </b-card-body>
          </app-collapse-item>
        </app-collapse>
      </div>
    </b-card>
    <!-- Table Container Card -->
    <b-card>
      <div class="m-2" />
      <b-table
        ref="refTableListAppeals"
        class="position-relative"
        :items="tableData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('table_columns.empty_text')"
        :busy="isLoading"
        hover
      >
        <!-- Custom Header -->
        <template #head()="{ label, field: { key, sortable } }">
          {{ $t(label) }}
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #cell(secteur)="data">
          {{ $t(data.item.profile.secteur.title) }}
        </template>

        <template #cell(object)="data">
          <b-link class="font-weight-bold" @click="goToDetails(data.item.id)">
            <!-- {{ data.item.object }} -->
            {{
              data.item.object.length > 30
                ? data.item.object.slice(0, 30) + '...'
                : data.item.object
            }}
          </b-link>
        </template>

        <template #cell(fullname)="data">
          {{ data.item.user.lastname + ' ' + data.item.user.firstname }}
        </template>
        <template #cell(demande)="data">
          {{
            $t(
              getTypeDemandebyCode(
                data.item.demande.data.code_demande === null
                  ? 0
                  : data.item.demande.data.code_demande
              )
            )
          }}
        </template>
        <template #cell(status)="data">
          <div v-if="data.item.status === 'comment'">
            <b-badge variant="secondary">
              {{ $t('complaints.complaint_list.status.comment') }}
            </b-badge>
          </div>
          <div v-if="data.item.status === 'not_treated'">
            <b-badge variant="warning">
              {{ $t('complaints.complaint_list.status.not_treated') }}
            </b-badge>
          </div>
          <div v-if="data.item.status === 'in_treatment'">
            <b-badge variant="success">
              {{ $t('complaints.complaint_list.status.in_treatment') }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-if="$can('show', 'complaint')"
            variant="primary"
            class="mr-1"
            @click="goToDetails(data.item.id)"
          >
            <feather-icon icon="EyeIcon" class="mr-25" />
            <span class="text-nowrap d-none d-md-none d-lg-inline">{{
              $t('button.see')
            }}</span>
          </b-button>
          <!-- <b-button
            v-if="$can('delete', 'complaint')"
            variant="danger"
            @click="wouldDelete(data.item.id)"
          >
            <i class="las la-trash-restore mr-25" />
            <span class="text-nowrap d-none d-md-none d-lg-inline">{{ $t('button.remove') }}</span>
          </b-button> -->
        </template>
      </b-table>
      <PaginationComponent
        :table-data="tableData"
        :per-page="paginationData.perPage"
        :total-rows="paginationData.total"
        @change="onPaginationChange"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardText,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BLink,
  BModal,
  VBModal,
  BBadge,
  BSpinner,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import localstorageService from '@/services/localstorage/localstorage.service'

// store modules and vuex utilities
// eslint-disable-next-line import/no-cycle
import etablissementsStoreModule from '@/store/etablissements'
// eslint-disable-next-line import/no-cycle
import statusStoreModule from '@/store/status'
// eslint-disable-next-line import/no-cycle
import appealsStoreModules from '@/store/appeals'

// eslint-disable-next-line import/no-cycle
import {
  registerStoreModule,
  // unregisterStoreModule,
} from '@/helpers/vuex-utils'

import PaginationComponent from '@/components/PaginationComponent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import utilsService from '@/services/utils/utils.service'
import formulairesStoreModule from '@/store/formulaire'

export default {
  components: {
    // ProductsListFilters,
    // UserListAddNew,

    BCard,
    BCardBody,
    BRow,
    BCardText,
    // BCollapse,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BSpinner,
    BModal,
    vSelect,
    PaginationComponent,
    AppCollapse,
    AppCollapseItem,
    flatPickr,
  },

  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },

  data() {
    return {
      isLoading: false,
      tableData: [],
      tableColumns: [
        { key: 'fullname', label: 'table_columns.fullname', sortable: true },
        { key: 'demande', label: 'table_columns.requests', sortable: true },
        {
          key: 'message',
          label: 'table_columns.object',
          sortable: true,
          thStyle: { width: '20%' },
        },
        { key: 'status', label: 'table_columns.status', sortable: true },
        { key: 'created_at', label: 'table_columns.date', sortable: true },
        { key: 'actions', label: 'table_columns.actions' },
      ],
      requests_table_data: [],
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      appealId: null,
      filteredOptions: [],
      listProfils: [],
      listStatus: [],
      listMetiers: [],
      listObjets: [
        { id: '1', title: 'Retard du moyen de transport' },
        { id: '2', title: 'Service client irrespectueux' },
        { id: '3', title: 'Problèmes de réservation' },
        { id: '4', title: 'Guide mal informé' },
        { id: '5', title: 'Itinéraire de visite insatisfaisant' },
        { id: '6', title: 'Guide irrespectueux ou désintéressé.' },
        { id: '7', title: 'Réservation annulée sans préavis' },
        { id: '8', title: 'Problèmes de facturation ou de paiement' },
        { id: '9', title: 'Bruit excessif' },
        { id: '10', title: 'Service lent ou inattentif' },
        { id: '11', title: 'Commande incorrecte' },
        { id: '12', title: "Problèmes d'hygiène ou de salubrité" },
      ],
      filters: {
        name: null,
        sector: null,
        object: null,
        message: null,
        profile_id: null,
        status: null,
        periode: null,
      },
    }
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        await this.loadStatus()
        await this.searchAppeal()
        await this.load_requests()
      },
    },
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.tableData = this.customCloneDeep(
            this.tableData.slice(0, val - 1)
          )
        }
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        this.searchAppeal()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        this.searchAppeal()
      },
    },
  },

  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStoreModule },
      { path: 'status', module: statusStoreModule },
      { path: 'appeals', module: appealsStoreModules },
      { path: 'formulaires', module: formulairesStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    return {
      requiredStoreModules,
    }
  },

  // beforeDestroy() {
  //   unregisterStoreModule(this.requiredStoreModules)
  // },

  methods: {
    ...mapActions('etablissements', {
      action_searchEtablissement: 'searchEtablissement',
      action_getListTypesEtablissement: 'getListTypesEtablissement',
    }),
    ...mapActions('formulaires', {
      action_searchFormulaires: 'searchFormulaires',
    }),
    ...mapActions('status', {
      action_fetchStatus: 'fetchStatus',
    }),
    ...mapActions('appeals', {
      action_searchAppeal: 'searchAppeal',
      action_deleteAppeal: 'deleteAppeal',
    }),
    // Chargement des profils

    getTypeDemandebyCode(code) {
      const selectedForm = utilsService.getFormulaireByCodeDemande(code)
      return selectedForm.title
    },
    load_requests() {
      this.isLoading = true
      const params = {
        user_id: localstorageService.getUserData().id,
        page: 1,
        perPage: 1000000000,
      }
      // console.log('⭕️load_requests params::: ', params);
      this.action_searchFormulaires(params)
        .then((res) => {
          // console.log('⭕️res::: ', res.data.data);
          if (res.status === 200) {
            this.isLoading = false
            this.requests_table_data = []
            res.data.data.forEach((item) => {
              console.log('⭕️⭕️⭕️ item::: ', item)
              const slug = utilsService.getSlugByCodeDemande(
                item.type_demande.code
              )
              const selectedForm = utilsService.getFormulaireByCodeClient(slug)
              let label = null
              switch (selectedForm.sector) {
                case 'UEH':
                  label = `Référence demande: ${item.reference} --- Établissement: ${item.user?.profile.nom_etablissement} de ${item.user?.firstname} ${item.user?.lastname} --- Demande: ${item.type_demande.title}`
                  break
                case 'UEAV':
                  label = `Référence demande: ${item.reference} --- Agence: ${item.user?.profile.nom_etablissement} de ${item.user?.firstname} ${item.user?.lastname} --- Demande: ${item.type_demande.title}`
                  break
                case 'UEGT':
                  label = `Référence demande: ${item.reference} --- Guide: ${item.user?.firstname} ${item.user.lastname} --- Demande: ${item.type_demande.title}`
                  break
                case 'UER':
                  label = `Référence demande: ${item.reference} --- Établissement: ${item.user?.profile.nom_etablissement} de ${item.user?.firstname} ${item.user?.lastname} --- Demande: ${item.type_demande.title}`
                  break
                default:
                  break
              }

              let build_object = {
                ...item.data,
                selectedForm,
                slug,
                user: item.user,
                user_id: item.user_id,
                id: item.id,
                statuses: item.statuses,
                created_at: item.created_at,
                updated_at: item.updated_at,
                duration: item.type_demande.duration,
                type_demande: item.type_demande,
                auto_evaluation: item.auto_evaluation,
                audit: item.audit,
                reference: item.reference,
                code_demande: item.type_demande.code,
                label,
                user: item.user,
              }

              this.requests_table_data.push(build_object)
            })
            // console.log('this.requests_table_data::: 🟢⭕️🟢', this.requests_table_data);
            // --------------------------------------------------------------
            this.paginationData.total = res.data.meta.total || 0
            if (this.paginationData.metaData.from === 0) {
              this.paginationData.metaData.from += 1
            } else {
              this.paginationData.metaData.from =
                this.paginationData.perPage * this.paginationData.currentPage -
                this.paginationData.perPage
            }
            this.paginationData.metaData.to =
              this.paginationData.perPage * this.paginationData.currentPage -
              this.paginationData.perPage +
              res.data.meta.total
            // --------------------------------------------------------------
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoading = false
        })
    },
    // Chargement des status
    async loadStatus() {
      try {
        const response = await this.action_fetchStatus()
        if (response.status === 200) {
          this.listStatus = response.data
        } else {
          console.log("La réponse n'a pas un status HTTP 200")
        }
      } catch (error) {
        console.log('Erreur lors du chargement de la liste des status', error)
      }
    },
    // Recherche des appeals
    async searchAppeal() {
      try {
        this.isLoading = true

        let periodeFormatted = null
        if (this.filters.periode && this.filters.periode !== '') {
          const periodeArray = this.filters.periode.split(' au ')
          if (periodeArray.length === 2) {
            periodeFormatted = {
              from: periodeArray[0],
              to: periodeArray[1],
            }
          } else if (periodeArray.length === 1) {
            periodeFormatted = {
              from: periodeArray[0],
              to: periodeArray[0],
            }
          }
        }

        const response = await this.action_searchAppeal({
          user_id: localstorageService.getUserData().id,
          message: this.filters.message,
          demande_id: this.filters.demande_id,
          status: this.filters.status,
          periode: periodeFormatted,
          // params for filter by pagination
          page: this.paginationData.currentPage,
          per_page: this.paginationData.perPage,
        })
        if (response.status === 200) {
          this.tableData = response.data.data
          console.log(this.tableData)
          this.isLoading = false
          // --------------------------------------------------------------
          this.paginationData.total = response.data.meta.total || 0
          if (this.paginationData.metaData.from === 0) {
            this.paginationData.metaData.from += 1
          } else {
            this.paginationData.metaData.from =
              this.paginationData.perPage * this.paginationData.currentPage -
              this.paginationData.perPage
          }
          this.paginationData.metaData.to =
            this.paginationData.perPage * this.paginationData.currentPage -
            this.paginationData.perPage +
            response.data.meta.total
          // --------------------------------------------------------------
        } else {
          console.log("La réponse n'a pas un status HTTP 200")
        }
      } catch (error) {
        this.isLoading = false
        console.error(
          'Erreur lors du chargement des données de la recherche de appeal',
          error
        )
      }
    },
    // Changement de page
    onPaginationChange(val) {
      this.paginationData.currentPage = val.currentPage
      this.paginationData.perPage = val.perPage
    },
    // Redirection vers la page de détails
    goToDetails(id) {
      localstorageService.setAppealId(id)
      this.$router.push({ name: 'appeal-details', params: { id } })
    },
    // Demande de consentement
    wouldDelete(id) {
      this.appealId = id
      this.$refs['modal-delete-ticket'].show()
    },
    // Suppression d'une appeal
    async deleteManuel() {
      this.isLoading = true
      try {
        const response = await this.action_deleteAppeal(this.appealId)
        if (response.status === 204) {
          this.isLoading = false
          this.$router.replace({ name: 'appeal-list' })
          await this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Suppresion réussie',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        } else {
          console.log("La réponse n'a pas un status HTTP 204")
        }
      } catch (error) {
        this.isLoading = false
        console.log('Erreur lors de la appeal', error)
        await this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Une erreur est survenue',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: '',
          },
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
